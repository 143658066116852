<template>
  <div class="card" v-if="partes">
    <h3 class="card-title">Ver partes diarios</h3>
    <div class="d-flex space-between w-100">
      <button @click="previous()" class="btn btn-outline-dark" style="max-width:auto!important; width: fit-content;">
        <icon feather="arrow-left"/>
      </button>
      <button @click="next()" class="btn btn-outline-dark">
        <icon feather="arrow-right"/>
      </button>
    </div>
    <div class="w-100 mt-2 mb-2">
      <div class="cols3 mb-2">
        <pd-input id="fecha" placeholder="Fecha" label="Seleccione fecha" type="date" v-model="day"/>
        <suggest
          v-if="user.tipo === 'RRHH' || user.tipo === 'dev' || user.tipo === 'Gerente'"
          id="area"
          label="Área"
          placeholder="Seleccione área.."
          :data="areasForSelect"
          :items="itemsArea"
          @selected="areaSelected"
        ></suggest>
        <div style="align-self: end;">
          <button class="btn btn-primary" @click.prevent="getPartesDiarios">Buscar</button>
        </div>
      </div>

      <table-list
        :showFilter="false"
        :data="partesForTableList"
        :header="headerPartes"
        :body="bodyPartes"
      />
    </div>
  </div>
</template>
<script>
import {DateTime} from 'luxon';
import Suggest from "@/components/elements/Suggest";
import ParteDiarioServices from '../services/ParteDiarioServices';
import {AreaServices} from '../../areas/services/AreaServices';
import TableList from '../../../components/elements/TableList.vue';
import Vuex from "vuex";

export default {
  name: "ListarPartesDiarios",
  components: {
    Suggest,
    TableList
  },
  data() {
    return {
      partes: null,
      day: null,
      area: null,
      areas: null,
      itemsArea: AreaServices.dataList(),
      area_id: null
    };
  },
  computed: {
    ...Vuex.mapState(["user"]),
    headerPartes() {
      return [
        {value: 'Empleado', sortable: true},
        {value: 'Estado', sortable: true},
        {value: 'Nota', sortable: true},
        {value: 'Info', sortable: true},
      ]
    },
    bodyPartes() {
      return [
        {
          class: 'w-100',
          data: [{value: 'empleado', class: 'important', label: 'Empleado'}]
        },
        {
          class: 'w-100',
          data: [{
            value: 'estado', label: "Estado", mutator: (item) => {
              if (item === 'Presente') {
                return `<span class="text-bold text-success">${item}</span>`
              } else {
                return `<span class="text-bold text-danger">${item}</span>`
              }
            }
          }]
        },
        {
          class: 'w-100',
          data: [{value: 'notas', label: "Nota"}]
        },
        {
          class: 'w-100',
          data: [
            {value: 'info.descripcion', label: "", class: "text-bold"},
            {value: 'info.desde', class: "text-secondary", label: ""},
            {value: 'info.hasta', class: "text-secondary", label: ""},
          ]
        }
      ]
    },
    partesForTableList() {
      const items = []
      this.partes.forEach((parte) => {
        const item = {
          empleado: parte.empleado.nombre,
          estado: parte.estado,
          notas: parte.notas,
          parte: parte
        }
        if (parte.licencia_id) {
          item.info = {
            descripcion: parte.licencia.descripcion,
            desde: 'Desde: ' + this.formatDate(parte.licencia_desde),
            hasta: 'Hasta: ' + this.formatDate(parte.licencia_hasta),
          }
        }
        items.push(item)
      })
      return items
    },
    areasForSelect() {
      const areasFiltered = this.areas;
      if (this.user.tipo === 'RRHH' || this.user.tipo === 'dev') {
        return areasFiltered;
      }
      if (this.user.tipo === 'Gerente') {
        return areasFiltered.filter(area => area.gerencia_id === this.user.persona.gerencia_id)
      }
      return []
    }
  },
  mounted() {
    if (this.$route.query.day) {
      this.day = DateTime.fromFormat(this.$route.query.day, 'yyyyLLdd').toFormat('yyyy-LL-dd')
    } else {
      this.day = DateTime.now().toFormat('yyyy-LL-dd')
    }
    AreaServices.api.getAll().then((areas) => {
      this.areas = [
        {id: null, descripcion: 'Todas las áreas', gerencia: {descripcion: ''}},
        ...areas
      ]
    });
    this.getPartesDiarios()
  },
  methods: {
    async getPartesDiarios() {
      const day = DateTime.fromFormat(this.day, 'yyyy-LL-dd')
      this.$router.replace({query: {day: day.toFormat('yyyyLLdd')}})
      this.partes = await ParteDiarioServices.api.getListadoPartes(this.day, this.area_id);
    },
    areaSelected(area) {
      if (area) {
        this.area = area;
        this.area_id = area.id;
      }
    },
    formatDate(date) {
      return DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')
    },
    previous() {
      const previous = DateTime.fromFormat(this.day, 'yyyy-LL-dd')
      this.day = previous.minus({day: 1}).toFormat('yyyy-LL-dd')
      this.getPartesDiarios()
    },
    next() {
      const next = DateTime.fromFormat(this.day, 'yyyy-LL-dd')

      this.day = next.plus({day: 1}).toFormat('yyyy-LL-dd')
      this.getPartesDiarios()
    }
  }
}
</script>
<style scoped>
.btn-outline-dark {
  border: none;
  padding: .3rem .75rem;
  min-width: 0;
  border: 1px solid var(--neutral-100);
  background-color: white;
  color: var(--neutral-100);
}

.btn-outline-dark:hover {
  background-color: white;
  color: var(--neutral-100);
}

</style>
